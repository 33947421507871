export function getValueEarliestInList(list, a, b) {
    const aIndex = list.indexOf(a)
    const bIndex = list.indexOf(b)
    
    if (aIndex >= 0 && bIndex >= 0) {
        return aIndex <= bIndex ? a : b
    }

    // if the list only contains a or b, return the one in the list
    if (aIndex >= 0) {
        return a
    }
    if (bIndex >= 0) {
        return b
    }

    return null
}